import { ErrorBoundary, withProfiler } from '@sentry/react';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AntConfigProvider from '../AntConfigProvider';
import App from '../App';
import AuthenticationProvider from '../AuthenticationProvider';
import ConfigProvider from '../ConfigProvider';
import GraphQLProvider from '../GraphQLProvider';
import Loading from '../Loading';
import OrganizationProvider from '../OrganizationProvider';
import PartnerConfigProvider from '../PartnerConfigProvider';
import Sentry from '../Sentry';
import withException from '../withException';

import ErrorBoundaryFallback from './ErrorBoundaryFallback';

import './styles.less';

const Root = () => (
  <ErrorBoundary fallback={ErrorBoundaryFallback}>
    <BrowserRouter>
      <ConfigProvider>
        <Sentry />
        <GraphQLProvider>
          <OrganizationProvider>
            <PartnerConfigProvider>
              <AuthenticationProvider>
                <AntConfigProvider>
                  <Suspense fallback={<Loading />}>
                    <App />
                  </Suspense>
                </AntConfigProvider>
              </AuthenticationProvider>
            </PartnerConfigProvider>
          </OrganizationProvider>
        </GraphQLProvider>
      </ConfigProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

export default withProfiler(withException(Root));
