import { createRoot } from 'react-dom/client';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Root from './components/Root';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Root />);
