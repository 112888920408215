import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PartnerConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PartnerConfigQuery = { partnerPublisherConfig: { termsAndConditions: string | null, privacyPolicy: string | null, offerTagsLabel: string | null, offerTagsEnabled: boolean, eventsInReportsEnabled: boolean, conversionReportEnabled: boolean, supportTicketsEnabled: boolean, editPostbacksEnabled: boolean, offersEnabled: boolean, smartlinksEnabled: boolean, offerMetricsInDashboardEnabled: boolean, referralsEnabled: boolean } };


export const PartnerConfigDocument = gql`
    query PartnerConfig {
  partnerPublisherConfig {
    termsAndConditions
    privacyPolicy
    offerTagsLabel
    offerTagsEnabled
    eventsInReportsEnabled
    conversionReportEnabled
    supportTicketsEnabled
    editPostbacksEnabled
    offersEnabled
    smartlinksEnabled
    offerMetricsInDashboardEnabled
    referralsEnabled
  }
}
    `;

/**
 * __usePartnerConfigQuery__
 *
 * To run a query within a React component, call `usePartnerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerConfigQuery(baseOptions?: Apollo.QueryHookOptions<PartnerConfigQuery, PartnerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerConfigQuery, PartnerConfigQueryVariables>(PartnerConfigDocument, options);
      }
export function usePartnerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerConfigQuery, PartnerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerConfigQuery, PartnerConfigQueryVariables>(PartnerConfigDocument, options);
        }
export function usePartnerConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartnerConfigQuery, PartnerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartnerConfigQuery, PartnerConfigQueryVariables>(PartnerConfigDocument, options);
        }
export type PartnerConfigQueryHookResult = ReturnType<typeof usePartnerConfigQuery>;
export type PartnerConfigLazyQueryHookResult = ReturnType<typeof usePartnerConfigLazyQuery>;
export type PartnerConfigSuspenseQueryHookResult = ReturnType<typeof usePartnerConfigSuspenseQuery>;
export type PartnerConfigQueryResult = Apollo.QueryResult<PartnerConfigQuery, PartnerConfigQueryVariables>;