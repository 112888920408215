import { Reference, StoreObject, TypePolicies } from '@apollo/client';

import {
  BlacklistedSubId,
  ClickBudget,
  MigrationState,
  Payout,
  PublisherMargin,
  SearchEntity,
  ServiceConfig,
  SkAdMapping,
  SkAdNetwork,
  SkAdPostbackLink,
} from '../../graphql.generated';

const typePolicies: TypePolicies = {
  BlacklistedSubId: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('BlacklistedSubId: no "__typename"');

        return;
      }

      const blacklistedSubId = object as Partial<BlacklistedSubId>;

      if (!blacklistedSubId.subId) {
        console.error(`${typename}: "subId" is required for caching`);

        return;
      }

      const offerId = String(blacklistedSubId.offer?.id || null);
      const publisherId = String(blacklistedSubId.publisher?.id || null);
      const advertiserId = String(blacklistedSubId.advertiser?.id || null);

      return `${typename}:${offerId}:${advertiserId}:${publisherId}:${blacklistedSubId.subId}`;
    },
  },
  ClickBudget: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('ClickBudget: no "__typename"');

        return;
      }

      const clickBudget = object as Partial<ClickBudget>;

      if (clickBudget.advertiser?.id === undefined) {
        console.error(`${typename}: "advertiser.id" is required for caching`);

        return;
      }

      if (clickBudget.offer?.id === undefined) {
        console.error(`${typename}: "offer.id" is required for caching`);

        return;
      }

      if (clickBudget.validity === undefined) {
        console.error(`${typename}: "validity" is required for caching`);

        return;
      }

      if (clickBudget.publisher) {
        return `${typename}:${clickBudget.offer.id}:${
          clickBudget.advertiser.id
        }:${clickBudget.publisher.id}:${String(clickBudget.validity)}`;
      }

      return `${typename}:${clickBudget.offer.id}:${
        clickBudget.advertiser.id
      }:${String(clickBudget.validity)}`;
    },
  },
  MigrationState: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('MigrationState: no "__typename"');

        return;
      }

      const migrationState = object as Partial<MigrationState>;

      if (migrationState.advertiser?.id === undefined) {
        console.error(`${typename}: "advertiser.id" is required for caching`);

        return;
      }

      const advertiserId = String(migrationState.advertiser?.id);

      return `${typename}:${advertiserId}`;
    },
  },
  Payout: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('Payout: no "__typename"');

        return;
      }

      const payout = object as Partial<Payout>;

      if (
        payout.offer === undefined ||
        (payout.offer && payout.offer.id === undefined)
      ) {
        console.error(
          `${typename}: "offer.id" is required for caching`,
          object,
        );

        return;
      }

      if (
        payout.publisher === undefined ||
        (payout.publisher && payout.publisher.id === undefined)
      ) {
        console.error(
          `${typename}: "publisher.id" is required for caching`,
          object,
        );

        return;
      }

      if (
        payout.eventType === undefined ||
        (payout.eventType && payout.eventType.id === undefined)
      ) {
        console.error(
          `${typename}: "eventType" is required for caching`,
          object,
        );

        return;
      }

      const offerId = String(payout.offer?.id || null);
      const publisherId = String(payout.publisher?.id || null);
      const eventTypeId = String(payout.eventType?.id || null);

      return `${typename}:${offerId}:${publisherId}:${eventTypeId}`;
    },
  },
  PublisherMargin: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('PublisherMargin: no "__typename"');

        return;
      }

      const publisherMargin = object as Partial<PublisherMargin>;

      if (publisherMargin.publisherId === undefined) {
        console.error(
          `${typename}: "publisherId" is required for caching`,
          object,
        );
      }

      const publisherId = String(publisherMargin.publisherId);

      return `${typename}:${publisherId}`;
    },
  },
  Query: {
    fields: {
      forms: {
        merge(existing: Reference[], incoming: Reference[]): Reference[] {
          return incoming;
        },
      },
    },
  },
  SearchEntity: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('SearchEntity: no "__typename"');

        return;
      }

      const searchEntity = object as Partial<SearchEntity>;

      if (searchEntity.type === undefined) {
        console.error(`${typename}: "type" is required for caching`, object);
      }

      return `${typename}:${String(searchEntity.type)}:${String(
        searchEntity.id,
      )}`;
    },
  },
  ServiceConfig: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('ServiceConfig: no "__typename"');

        return;
      }

      const serviceConfig = object as Partial<ServiceConfig>;

      if (serviceConfig.key === undefined) {
        console.error(`${typename}: "key" is required for caching`);

        return;
      }

      if (serviceConfig.service === undefined) {
        console.error(`${typename}: "service" is required for caching`);

        return;
      }

      const key = String(serviceConfig.key);
      const service = String(serviceConfig.service);

      return `${typename}:${key}:${service}`;
    },
  },
  SkAdMapping: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('SkAdMapping: no "__typename"');

        return;
      }

      const campaign = object as Partial<SkAdMapping>;

      if (!campaign.networkId) {
        console.error(`${typename}: "networkId" is required for caching`);

        return;
      }

      if (!campaign.appId) {
        console.error(`${typename}: "appId" is required for caching`);

        return;
      }

      const campaignId = String(campaign.campaignId || null);

      return `${typename}:${campaign.networkId}:${campaign.appId}:${campaignId}`;
    },
  },
  SKAdNetwork: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('SKAdNetwork: no "__typename"');

        return;
      }

      const network = object as Partial<SkAdNetwork>;

      if (!network.networkId) {
        console.error(`${typename}: "networkId" is required for caching`);

        return;
      }

      return `${typename}:${network.networkId}`;
    },
  },
  SkAdPostbackLink: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('SkAdPostbackLink: no "__typename"');

        return;
      }

      const postbackLink = object as Partial<SkAdPostbackLink>;

      if (!postbackLink.networkId) {
        console.error(`${typename}: "networkId" is required for caching`);

        return;
      }

      if (!postbackLink.offerId) {
        console.error(`${typename}: "offerId" is required for caching`);

        return;
      }

      const campaignId = String(postbackLink.campaignId || null);

      return `${typename}:${postbackLink.networkId}:${postbackLink.offerId}:${campaignId}`;
    },
  },
};

export default typePolicies;
