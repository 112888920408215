import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { useAuthentication } from '../AuthenticationProvider';
import { useConfig } from '../ConfigProvider';

import authLink, { setAuthorization } from './authLink';
import omitTypeNameLink from './omitTypeNameLink';
import sentryLink from './sentryLink';
import typePolicies from './typePolicies';
import useErrorLink from './useErrorLink';

const GraphQLProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { accessToken } = useAuthentication();
  const { datagonUrl } = useConfig();

  useEffect(() => {
    if (accessToken) {
      setAuthorization(accessToken);
    }
  }, [accessToken]);

  const cache = useMemo(() => new InMemoryCache({ typePolicies }), []);

  const errorLink = useErrorLink();

  const client = useMemo(() => {
    const uploadLink = createUploadLink({ uri: datagonUrl });

    const link = authLink.concat(
      ApolloLink.from([omitTypeNameLink, errorLink, sentryLink, uploadLink]),
    );

    return new ApolloClient({
      cache,
      connectToDevTools: true,
      defaultOptions: {
        query: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
      },
      link,
      queryDeduplication: false,
    });
  }, [cache, datagonUrl, errorLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

GraphQLProvider.displayName = 'GraphQLProvider';

export default GraphQLProvider;
