import { createContext, FC, PropsWithChildren, useContext } from 'react';

import Loading from '../Loading';
import Maintenance from '../Maintenance';

import {
  PartnerConfigQuery,
  usePartnerConfigQuery,
} from './PartnerConfig.generated';

type Config = PartnerConfigQuery['partnerPublisherConfig'];

export const PartnerConfigContext = createContext<Config | undefined>(
  undefined,
);

export const usePartnerConfig = (): Config => {
  const config = useContext(PartnerConfigContext);

  if (!config) {
    throw new Error('No platform config');
  }

  return config;
};

const PartnerConfigProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { data, error, loading } = usePartnerConfigQuery();

  const config = data?.partnerPublisherConfig;

  if (error) {
    return <Maintenance />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <PartnerConfigContext.Provider value={config}>
      {children}
    </PartnerConfigContext.Provider>
  );
};

PartnerConfigProvider.displayName = 'PartnerConfigProvider';

export default PartnerConfigProvider;
